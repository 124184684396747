.tableRow:hover {
  background-color: lightgray;
  cursor: pointer;
}
tbody {
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  right: 70%;
  transform: translate(50%);
}

@media only screen and (max-width: 768px) {
  .contractSearch {
    margin: 60px 0 25px 0 !important;
  }
}
