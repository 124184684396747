.wrapperTransactionTable {
  background-color: #fff;
  padding-top: 30px;
  margin-bottom: 40px;
  min-height: 150px;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 4px 20px 0px rgba(133, 133, 133, 0.24);
}
.wrapperTransactionTableText {
  margin-left: 20px;
}

.paymentProgress {
  position: absolute;
  left: 50%;
  top: 70%;
}

.tableDate {
  margin-left: 30px;
}

.contract-title {
  font-weight: 500;
  font-size: 20px;
  color: #1f1f1f;
  line-height: 24px;
  margin-left: 22px;
}

.dates {
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-left: 15px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.date {
  z-index: 12;
  border-radius: 4px !important;
}

.dateActive {
  border: 1px solid rgb(0, 128, 0);
}

.dates > button {
  width: 80px;
  height: 50px;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.dates .MuiInputBase-root {
  border: none !important;
}

.wrapperTransactionTable #demo-simple-select {
  width: 150px;
  text-align: start;
}

.no_payment_hostory {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .contract-title {
    font-size: 18px;
  }
}
