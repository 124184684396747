.searchInput::before,
::after {
  display: none;
}

@media only screen and (max-width: 768px) {
  .searchInput {
    margin-top: 0;
  }
}
