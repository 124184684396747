@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Matter';
  src: url('./assets/fonts/Matter-Regular.eot');
  /* IE9 Compat Modes */
  src:
    url('./assets/fonts/Matter-Regular.eot') format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/Matter-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./assets/fonts/Matter-Regular.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('./assets/fonts/Matter-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/Matter-Regular.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App {
  height: 100%;
}

.logo {
  height: 80px;
  width: 100px;
}

.headerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: green;
  padding: 0 25px;
}

.headerText {
  font-size: 14px;
  color: white;
}

.app-button {
  background-color: green !important;
  color: white !important;
  margin-top: 10px !important;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #11ba5f;
}

*::-webkit-scrollbar-thumb:hover {
  background: #0e8f4f;
}

*::-webkit-scrollbar-thumb:active {
  background: #11ba5f;
}

@media screen and (max-width: 768px) {
  *::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
