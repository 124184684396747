.adminDashboard {
  text-align: end;
}

.adminButtons {
  width: 100%;
  height: 10vh;
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-start;
  align-items: center;
}

nav {
  display: flex;
  width: 100%;
  height: 10vh;
  align-items: center;
}

.adminDashboard .title {
  color: #232323;
  font-size: 26px !important;
}

.headSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 35px 30px 80px;
}

.table {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.MuiTableCell-body {
  border: none !important;
}

.MuiFormHelperText-root {
  font-family: 'Montserrat' !important ;
  font-style: normal !important;
  font-weight: 450 !important;
  font-size: 10px !important;
  line-height: 20px !important;
  color: #ff4242 !important;
}

@media screen and (max-width: 768px) {
  .headSection {
    display: grid;
    row-gap: 40px;
    padding: 36px 25px 15px 25px;
    text-align: start;
  }

  .css-b6bm3b {
    margin-right: 0 !important;
  }

  .adminDashboard {
    text-align: start;
  }
}
@media only screen and (max-width: 600px) {
  .headSection {
    flex-direction: column;
    align-items: flex-start;
    padding: 27px 0 25px 21px;
    gap: 0;
    row-gap: 28px;
  }
  .adminDashboard .title {
    font-size: 18px !important;
  }
}
