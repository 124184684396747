.redesignedTable td {
  border: none !important;
  /* border-top: 1px solid #e0e0e0 !important; */
}

.redesignedTable td {
  border-bottom: 1px solid #eaeaea !important;
}

.redesignedTable tr:last-child td {
  border-bottom: none !important;
}

.redesignedTable tr:last-child:hover td {
  border-bottom: none !important;
}

.redesignedTable tr:nth-child(even) {
  background-color: red;
}

.redesignedTable tr:last-child td:first-child {
  border-bottom-left-radius: 15px !important;
}

.redesignedTable tr:last-child td:last-child {
  border-bottom-right-radius: 15px !important;
}

.redesignedTable td:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none !important;
}

.redesignedTable td:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none !important;
}

.tableHead tr th:first-of-type {
  border-bottom-left-radius: 0px;
}

.tableHead tr th:first-of-type {
  border-top-left-radius: 15px;
}

.tableHead tr th:last-of-type {
  border-bottom-right-radius: 0px;
}

.tableHead tr th:last-of-type {
  border-top-right-radius: 15px;
}
