.ContractData {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
}

.contact {
  background: #ffffff;
  max-width: 1500px;
  padding: 40px;
  width: 100%;
}

.MuiPaper-root {
  margin-top: 0 !important;
}

.dataContract {
  width: 100%;
  display: flex;
  column-gap: 50px;
}

.content {
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}

.countries {
  height: 47px;
  border-radius: 8px;
  width: 100%;
  margin-top: 5px;
  border: 1px solid;
  margin-bottom: 3px;
}

.inputs {
  height: 40px;
  border-radius: 8px;
  width: 250px;
  margin-top: 5px;
  border: 1px solid;
}

.addBtn {
  width: 250px;
  color: green !important;
}

.titles {
  color: #404040 !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
}

.values {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #8f8e92;
}

@media screen and (max-width: 960px) {
  .infoContract > div {
    flex-direction: column;
    width: 100%;
    align-items: flex-start !important;
    padding: 10px 20px !important;
    gap: 20px;
    height: 100% !important;
  }
}
